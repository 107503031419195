import posthog, { PostHog } from 'posthog-js'
import { isLocal } from './helpers'

export let posthogInstance: PostHog

export const init = () => {
  if (isLocal()) {
    console.log('[PostHog] Development mode – analytics disabled')
    return
  }

  posthogInstance = posthog.init(import.meta.env.VITE_REACT_POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: true,
  })
}

export const identify = (
  userId: string,
  properties?: Record<string, unknown>
) => {
  if (isLocal()) return
  posthogInstance.identify(userId, properties)
}

export const capture = (
  event: string,
  properties?: Record<string, unknown>
) => {
  if (isLocal()) return
  posthogInstance.capture(event, properties)
}

export const reset = () => {
  if (isLocal()) return
  posthogInstance.reset()
}
