import './index.css'

import ReactDOM from 'react-dom/client'
import * as FirebaseApp from 'services/firebase'
import * as TagManager from 'services/gtm'
import * as Sentry from 'services/sentry'
import * as PostHog from 'utils/posthog'

import App from './App'

Sentry.init()
TagManager.init()
FirebaseApp.init()
PostHog.init()

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
